import React, { useState, useEffect } from 'react';
import './RotatingText.css'; // Import the CSS file

const RotatingText = () => {
  const texts = ["y Mas!!", "et Plus", "& More"];
  const [currentText, setCurrentText] = useState(texts[0]);
  const [index, setIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState('fade-in');

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass('fade-out');
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFadeClass('fade-in');
      }, 200);
    }, 4000);

    return () => clearInterval(interval);
  }, [texts.length]);

  useEffect(() => {
    setCurrentText(texts[index]);
  }, [index, texts]);

  return <span className={fadeClass}>{currentText}</span>;
};

export default RotatingText;