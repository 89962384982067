import React, { useState } from "react";
import "./tabbing.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const tabData = [
  {
    cls: "h-item-1",
    title: "Moonwalk App",
    image: "images/moonwalk.png",
    imageLogo: "images/animation1.png",
    url: "https://moonwalk.fit",
    details: [
      "App Dev",
      "Web 3",
      "Smart Contract",
      "Design",
      "Branding",
      "Strategy",
    ],
    description:
      "Gamify your fitness journey with Moonwalk. Earn rewards for staying active.",
    vimg: "images/video1.png",
    sliderImages: [
      "images/moonwalk_image_1.png",
      "images/moonwalk_image_2.png",
      "images/moonwalk_image_3.png",
    ], // Add slider images here
    sbg: "images/sbg1.png",
  },
  {
    cls: "h-item-2",
    title: "Golfn App",
    image: "images/golfn.png",
    imageLogo: "images/animation2.png",
    url: "https://golfn.com",
    details: [
      "App Dev",
      "Software",
      "Web 3",
      "Consulting",
      "Strategy",
      "Tech Stuff",
    ],
    description: "Spid3r built a digital caddy for golfers. Play golf and earn.",
    vimg: "images/video2.png",
    sliderImages: [
      "images/golfn_image_1.png",
      "images/golfn_image_3-1.png",
      "images/golfn_image_3.png",
    ], // Add slider images here
    sbg: "images/sbg2.png",
  },
  {
    cls: "h-item-3",
    title: "BONK",
    image: "images/bonk.png",
    imageLogo: "images/animation3.png",
    url: "https://bonkcoin.com",
    details: [
      "Web",
      "Smart Stuff",
      "Apps",
      "Games",
      "Utility",
      "Audits",
    ],
    description: "Official BONK tech partner since the xmas airdrop.",
    vimg: "images/video2.png",
    sliderImages: [
      "images/bonk_image_1.png",
      "images/bonk_image_2.png",
      "images/bonk_image_3.png",
    ], // Add slider images here
    sbg: "images/sbg3.png",
  },
  {
    cls: "h-item-4",
    title: "Axiom Caching",
    image: "images/axiom.png",
    imageLogo: "images/animation4.png",
    url: "https://app.axiomfoundation.io/",
    details: [
      "Concept",
      "Design",
      "Full Stack",
      "NFT",
      "Caching",
      "Web 3",
    ],
    description: "Axiom Caching is considered the best NFT caching system in the universe.",
    vimg: "images/video4.png",
    sliderImages: [
      "images/axiom_image_1.png",
      "images/axiom_image_2.png",
      "images/axiom_image_3.png",
    ], // Add slider images here
    sbg: "images/sbg4.png",
  },
];

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`slick-prev ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 11.25C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75L4 11.25ZM20 12.75L20.75 12.75L20.75 11.25L20 11.25L20 12.75ZM4 12.75L20 12.75L20 11.25L4 11.25L4 12.75Z"
          fill="#FEFEFE"
        />
        <path
          d="M8.99996 7L4 12L9 17"
          stroke="#FEFEFE"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      className={`slick-next ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20 12.75C20.4142 12.75 20.75 12.4142 20.75 12C20.75 11.5858 20.4142 11.25 20 11.25L20 12.75ZM4 11.25L3.25 11.25L3.25 12.75L4 12.75L4 11.25ZM20 11.25L4 11.25L4 12.75L20 12.75L20 11.25Z"
          fill="#FEFEFE"
        />
        <path
          d="M15 17L20 12L15 7"
          stroke="#FEFEFE"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

const Tabbing = () => {
  const [activeTab, setActiveTab] = useState(0);

  let settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const currentTab = tabData[activeTab];
  return (
    <div className="tabbing-wrapper">
      <div className="container">
        <div className="tabbing-main">
          <div className="tabbing-header">
            {tabData.map((tab, index) => (
              <div
                key={index}
                className={`header-item ${tab.cls} ${index === activeTab ? "active" : ""
                  }`}
                onClick={() => handleTabClick(index)}
              >
                <img
                  src={tab.imageLogo}
                  alt={`animation${index + 1}`}
                  className="img-fluid"
                />
                <div className="regular-text">
                  <h3>{tab.title}</h3>
                </div>
              </div>
            ))}
          </div>

          <div className="tabbing-fixed-slides">
            {/* List */}
            <div className="fixed-item fixed-detail">
              <div className="img-logo">
                <a target="_blank" href={currentTab.url}><img
                  src={currentTab.image}
                  alt={currentTab.title}
                  className="img-fluid"
                /></a>
              </div>
              <div className="list-data">
                {currentTab.details.map((detail, index) => (
                  <div className="stuff-data" key={index}>
                    <img
                      src="images/stuff.svg"
                      alt="stuff"
                      className="img-fluid"
                    />
                    <div className="regular-text">
                      <h4>{detail}</h4>
                    </div>
                  </div>
                ))}
              </div>
              <div className="main-detail">
                <p>{currentTab.description}</p>
              </div>
            </div>

            {/* Slider */}
            <div className="fixed-item fixed-slider">
              <Slider {...settings}>
                {currentTab.sliderImages.map((image, index) => (
                  <div className="" key={index}>
                    <img
                      src={image}
                      alt={`slide ${index + 1}`}
                      className="img-fluid"
                    />
                  </div>
                ))}
              </Slider>
              <img
                src={currentTab.sbg}
                alt="sbg"
                className="img-fluid slide-bg-img"
              />
            </div>

            {/* Video */}
            <div className="fixed-item fixed-video hide-on-mobile">
              {/* <img src={currentTab.vimg} alt="video" className="img-fluid" /> */}
              <Link to={currentTab.url} target="_blank" className="bg-blured pointer">
                <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M61.25 13.55C61.25 11.8698 61.25 11.0298 60.9231 10.388C60.6354 9.82354 60.1765 9.3646 59.612 9.07698C58.9703 8.75 58.1302 8.75 56.45 8.75H26.0509C25.3171 8.75 24.9502 8.75 24.605 8.83289C24.2989 8.90638 24.0062 9.02759 23.7378 9.19208C23.4351 9.3776 23.1756 9.63703 22.6568 10.1559L20.8943 11.9184C19.7062 13.1064 19.1122 13.7005 18.8896 14.3855C18.6939 14.988 18.6939 15.637 18.8896 16.2396C19.1122 16.9245 19.7062 17.5186 20.8943 18.7066L26.5126 24.325C27.7007 25.513 28.2947 26.107 28.5173 26.792C28.713 27.3946 28.713 28.0436 28.5173 28.6461C28.2947 29.3311 27.7007 29.9251 26.5126 31.1132L5.95711 51.6687C4.76905 52.8568 4.17503 53.4508 3.95246 54.1358C3.75669 54.7384 3.75669 55.3874 3.95247 55.9899C4.17504 56.6749 4.76907 57.2689 5.95713 58.457L11.5432 64.043C12.7313 65.2311 13.3253 65.8251 14.0103 66.0477C14.6128 66.2434 15.2619 66.2434 15.8644 66.0477C16.5494 65.8251 17.1434 65.2311 18.3314 64.043L38.887 43.4875C40.075 42.2995 40.669 41.7055 41.354 41.4829C41.9566 41.2871 42.6056 41.2871 43.2081 41.4829C43.8931 41.7055 44.4872 42.2995 45.6752 43.4876L51.2934 49.1059C52.4815 50.2939 53.0755 50.8879 53.7605 51.1105C54.363 51.3063 55.0121 51.3063 55.6146 51.1105C56.2996 50.888 56.8936 50.2939 58.0817 49.1059L59.8442 47.3434C60.363 46.8245 60.6224 46.5651 60.808 46.2624C60.9725 45.9939 61.0937 45.7013 61.1672 45.3952C61.25 45.0499 61.25 44.683 61.25 43.9493V13.55Z" fill="#FEFEFE" />
                  <path d="M61.25 13.55C61.25 11.8698 61.25 11.0298 60.9231 10.388C60.6354 9.82354 60.1765 9.3646 59.612 9.07698C58.9703 8.75 58.1302 8.75 56.45 8.75H26.0509C25.3171 8.75 24.9502 8.75 24.605 8.83289C24.2989 8.90638 24.0062 9.02759 23.7378 9.19208C23.4351 9.3776 23.1756 9.63703 22.6568 10.1559L20.8943 11.9184C19.7062 13.1064 19.1122 13.7005 18.8896 14.3855C18.6939 14.988 18.6939 15.637 18.8896 16.2396C19.1122 16.9245 19.7062 17.5186 20.8943 18.7066L26.5126 24.325C27.7007 25.513 28.2947 26.107 28.5173 26.792C28.713 27.3946 28.713 28.0436 28.5173 28.6461C28.2947 29.3311 27.7007 29.9251 26.5126 31.1132L5.95711 51.6687C4.76905 52.8568 4.17503 53.4508 3.95246 54.1358C3.75669 54.7384 3.75669 55.3874 3.95247 55.9899C4.17504 56.6749 4.76907 57.2689 5.95713 58.457L11.5432 64.043C12.7313 65.2311 13.3253 65.8251 14.0103 66.0477C14.6128 66.2434 15.2619 66.2434 15.8644 66.0477C16.5494 65.8251 17.1434 65.2311 18.3314 64.043L38.887 43.4875C40.075 42.2995 40.669 41.7055 41.354 41.4829C41.9566 41.2871 42.6056 41.2871 43.2081 41.4829C43.8931 41.7055 44.4872 42.2995 45.6752 43.4876L51.2934 49.1059C52.4815 50.2939 53.0755 50.8879 53.7605 51.1105C54.363 51.3063 55.0121 51.3063 55.6146 51.1105C56.2996 50.888 56.8936 50.2939 58.0817 49.1059L59.8442 47.3434C60.363 46.8245 60.6224 46.5651 60.808 46.2624C60.9725 45.9939 61.0937 45.7013 61.1672 45.3952C61.25 45.0499 61.25 44.683 61.25 43.9493V13.55Z" fill="url(#paint0_linear_22_80)" />
                  <defs>
                    <linearGradient id="paint0_linear_22_80" x1="-30.5843" y1="38.0936" x2="56.9294" y2="57.5387" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#4EE1F9" />
                      <stop offset="0.515" stop-color="#86ED82" />
                      <stop offset="1" stop-color="#BBF910" />
                    </linearGradient>
                  </defs>
                </svg>

              </Link>
            </div>
          </div>

          {/* Conditionally Render the Background Shape */}
          {activeTab === 0 && (
            <>
              <img
                src="images/bgshape1.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-lg-block"
              />
              <img
                src="images/bgshapet1.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-md-block d-lg-none"
              />
              <img
                src="images/bgshapem1.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-block d-md-none"
              />
            </>
          )}
          {activeTab === 1 && (
            <>
              <img
                src="images/bgshape2.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-lg-block"
              />
              <img
                src="images/bgshapet2.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-md-block d-lg-none"
              />
              <img
                src="images/bgshapem2.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-block d-md-none"
              />
            </>
          )}
          {activeTab === 2 && (
            <>
              <img
                src="images/bgshape3.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-lg-block"
              />
              <img
                src="images/bgshapet3.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-md-block d-lg-none"
              />
              <img
                src="images/bgshapem3.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-block d-md-none"
              />
            </>
          )}
          {activeTab === 3 && (
            <>
              <img
                src="images/bgshape4.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-lg-block"
              />
              <img
                src="images/bgshapet4.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-none d-md-block d-lg-none"
              />
              <img
                src="images/bgshapem4.png"
                alt="bg-shape"
                className="img-fluid bg-shape d-block d-md-none"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tabbing;
