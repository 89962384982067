import React from 'react';
import Banner2 from '../components/Banner2';
import What from '../components/What';
import Tabbing from '../components/Tabbing';
import Projects from '../components/Projects';
import Service from '../components/Service';
import Team from '../components/Team';
import Togather from '../components/Togather';
import Touch from '../components/Touch';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
      <Banner2 />
      <What />
      <Tabbing />
      <Projects />
      <Service />
      <Team />
      <Togather />
      <Touch />
      <Footer />
    </div>
  )
}

export default Home;
