import React from "react";
import './team.css';

const Team = () => {

  const teamData = [
    { imgSrc: "images/swolquek.png", altText: "team-person", handle: "Swol Sol" },
    { imgSrc: "images/jefferson-pfp.jpg", altText: "team-person", handle: "Jefferson" },
    { imgSrc: "images/Quekz_2361_2024-09-24.png", altText: "team-person", handle: "Jade" },
    { imgSrc: "images/dena.png", altText: "team-person", handle: "dena" },
    { imgSrc: "images/clement.png", altText: "team-person", handle: "Clement" },
    { imgSrc: "images/kevin-pfp.jpg", altText: "team-person", handle: "Kevin" },
    { imgSrc: "images/Quekz_985_2024-09-24.png", altText: "team-person", handle: "Theo" },
    { imgSrc: "images/Quekz_1491_2024-09-24.png", altText: "team-person", handle: "El Walid" },
    { imgSrc: "images/Quekz_1701_2024-09-24.png", altText: "team-person", handle: "Pierre" },
    { imgSrc: "images/Quekz_2417_2024-09-24.png", altText: "team-person", handle: "Samy" },
    { imgSrc: "images/Quekz_3117_2024-09-24.png", altText: "team-person", handle: "Mantrax" },
    { imgSrc: "images/Quekz_4113_2024-09-24.png", altText: "team-person", handle: "Yazh" },
  ];

  return (
    <>
     <div className="team-wrapper">
      <div className="container">
        <div className="team-main">
          <div className="team-data">
            <div className="title-box">
              <div className="regular-text">
                <p>Meet our Team USA/France</p>
              </div>
            </div>
            <div className="team-box">
              {teamData.map((teamMember, index) => (
                <div key={index} className="team-item">
                  <div className="team-img">
                    <img
                      src={teamMember.imgSrc}
                      alt={teamMember.altText}
                      className="img-fluid"
                    />
                  </div>
                  <div className="team-title">
                    <h4>{teamMember.handle}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Team;
